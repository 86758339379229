/**
 * typography
 */

h1 {
  font-size: 4rem;
  line-height: 1.2;
  font-weight: 300;
  margin: 0;
}

h2 {
  font-size: 3rem;
  line-height: 1.4;
  font-weight: 400;
  margin: 0;
}

h3 {
  font-size: 2.4rem;
  line-height: 1.3;
  font-weight: 400;
  margin: 0;
  @media (max-width: 767px) {
    font-size: 24px;
  }
  @media (max-width: 540px) {
    font-size: 18px;
  }
}

.content-medium {
  font-weight: 500;
}

.content_12 {
  font-size: 1.2rem;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.content_14 {
  font-size: 1.4rem;
  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.content_18 {
  font-size: 1.8rem;
  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.content_20 {
  font-size: 2rem;
  @media (max-width: 768px) {
    font-size: 20px;
  }
}

.content_22 {
  font-size: 2.2rem;
  @media (max-width: 768px) {
    font-size: 22px;
  }
}

.content_35 {
  font-size: 3.5rem;
  @media (max-width: 768px) {
    font-size: 35px;
  }
}

.content_40 {
  font-size: 4rem;
  @media (max-width: 768px) {
    font-size: 40px;
  }
}

.content_60 {
  font-size: 6rem;
  @media (max-width: 768px) {
    font-size: 45px;
  }
}

.width_100 {
  width: 100%;
}
.width_75 {
  width: 75%;
}
.width_80 {
  width: 80%;
}
.width_50 {
  width: 50%;
}

.text-blue {
  color: #1C75BC;
}
.text-red {
  color: #ED1B2F;
}
.text-blue-03 {
  color: $blue-03;
}
.rotate-180 {
  transform: rotate(180deg);
}
.rotate-90 {
  transform: rotate(90deg);
}

.text-16 {
  font-size: 1.6rem;
  @media (max-width: 768px) {
    font-size: 16px;
  }
}
.mb-32 {
  margin-bottom: 3.2rem;
  @media (max-width: 768px) {
    margin-bottom: 32px;
  }
}
.mt-31 {
  margin-top: 3.2rem;
  @media (max-width: 768px) {
    margin-top: 32px;
  }
}
.toast-container {
  .toast {
    width: auto;
    .toast-title {
      font-size: 1.6rem;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
}
.text-success {
  color: $green-02!important;
}
.bg-success {
  background-color: rgba(39, 174, 96, 0.2)!important;
}
.text-danger {
  color: $red!important;
}
.bg-danger {
  background-color: rgba(237, 27, 47, 0.2)!important;
}
.text-grey {
  color: $grey-02!important;
}


.label-new {
  width: 14.4rem;
  height: 7.2rem;
  position: absolute;
  right: 1.2rem;
  top: 0;
  background: url('/pic/arrow-new.svg') no-repeat 50% 100% / contain;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  font-size: 1.8rem;
  padding-bottom: 1rem;
  z-index: 10;
}

.upper-russian {
  list-style-type: none;
}

.upper-russian li:before {
  display: inline-block;
  margin-left: -1.5em;
  margin-right: .55em;
  text-align: right;
  width: .95em;
}

.upper-russian li:first-child:before {
  content: "А.";
}

.upper-russian li:nth-child(2):before {
  content: "Б.";
}