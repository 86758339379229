.olympic {
  img {
    max-width: 100%;
  }
  &__header {
    display: flex;
    align-items: center;
    > div {
      flex-shrink: 1;
      flex-basis: 0;
    }
    &__text {
      flex-grow: 2;
    }
    &__img {
      flex-grow: 1;
      text-align: right;
    }
  }
  &__text {
    flex-grow: 2;
    hr {
      border: 0.2rem solid $danger;
      width: 50%;
      margin: 1.6rem 0 1.6rem;
    }
  }
}