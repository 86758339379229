
.section__lk-content-item-right {
  width: auto;
  @media (max-width: 576px){
    width: 100%;
  }
}
.msp-folder {
  &__header {
    align-items: center;
    margin-bottom: 20px!important;
    overflow:hidden;
    display:inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    a {
      color: #323232;
    }
    &__name {
      color: #1C75BC;
      margin: 0 1rem;
    }
  }
  .orders-item {
    border-left: 1px solid #F2F2F2;
    margin-left: -2.4rem;
  }
  .section__lk-content-item-data {
    cursor: pointer;
  }
  &.can-edit {
    @media (max-width: 576px) {
      padding-top: 46px;
    }
  }
}
.collapse {
  .section__lk-content-item {
    background: none!important;
  }
}
