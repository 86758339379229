.admin-comment {
  &__item {
    background: #fafafa;
    padding: 3.6rem 4.8rem;
  }
  &__text {
    word-break: break-all;
  }
  &__avatar {
    width: 9.6rem;
    height: 9.6rem;
    background: url('/pic/user.svg') center center no-repeat, #BDBDBD;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.04), 0 2px 6px 0 rgba(0, 0, 0, 0.04), 0 16px 24px 0 rgba(0, 0, 0, 0.10);
    border-radius: 40%;
    flex: 9.6rem 0 0;
  }
  &__name {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.4rem;
    margin-bottom: 0.4rem;
  }
  &__date {
    color: #BDBDBD;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    margin-bottom: 0.4rem;
  }
  &__comment {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    &__toolbar {
      border-left: 2px solid #BDBDBD;
      height: 100%;
      padding-left: 24px;
    }
  }
  @media (max-width: 767px) {
    &__name {
      font-size: 18px;
      line-height: 1;
    }
    &__comment {
      border-top: 2px solid #BDBDBD;
      margin-top: 24px;
      padding-top: 24px;
      font-size: 12px;
      &__toolbar {
        border-left: none;
        padding-left: 0;
      }
    }
  }
  .btn-circle {
    padding: 4px;
    width: 24px;
    height: 24px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}