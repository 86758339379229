/**
 * form-item.scss
 *
 */

.section__lk-order-form label {
  @media (max-width: 576px) {
    width: 100%!important;
  }
}
.form-item {
  margin-bottom: 2.4rem;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 24px;
  }

  &_groupped {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 3.2rem;

    @media screen and (max-width: 768px) {
      margin-bottom: 32px;
    }

    @media (max-width: 576px) {
      display: block;
      margin-bottom: 24px;
    }

    &_required {
      > label span {
        &:after {
          content: '*';
          color: $red;
          margin-left: .5rem;

          @media (max-width: 768px) {
            margin-left: 4px;
          }
        }
      }
    }

    > label {
      width: 20.8rem;
      flex: 0 0 20.8rem;
      padding-right: 2.4rem;
      display: flex;
      align-items: center;
      margin-bottom: 0;

      @media (max-width: 768px) {
        width: 100%;
        flex: 0 0 170px;
        .custom-tooltip {
          margin-left: 10px;

          @media (max-width: 576px) {
            &__content {
              left: auto;
              right: 0;
              max-width: 320px;
            }
          }
        }
      }

      @media (max-width: 576px) {
        max-width: 100%;
        flex: 0 0 100%;
        justify-content: space-between;
        margin-bottom: 8px;
        padding: 0!important;
      }

      + * {
        max-width: calc(100% - 23.2rem);
        flex: 0 0 calc(100% - 23.2rem);

        @media (max-width: 768px) {
          max-width: calc(100% - 170px)!important;
          flex: 0 0 calc(100% - 170px)!important;
        }

        @media (max-width: 576px) {
          max-width: 100%!important;
          flex: 0 0 100%!important;
        }
      }
    }
    .description {
      font-size: 1.4rem;
      margin-top: .6rem;
      color: $blue-03;

      @media (max-width: 768px) {
        font-size: 14px;
      }

      @media (max-width: 576px) {
        font-size: 12px;
        margin-top: 8px;
      }
    }
    &__with_icon {
      img {
        position: absolute;
        padding: 1.2rem 1.4rem;
        z-index: 1;
        @media (max-width: 576px) {
          padding: 12px 14px;
        }
      }
      input {
        padding-left: 4.2rem;
        @media (max-width: 576px) {
          padding-left: 48px!important;
        }
      }
    }
  }

  &_editable {
    position: relative;

    span {
      position: absolute;
      right: 2.4rem;
      top: 50%;
      transform: translateY(-50%);
      text-decoration: underline;
      cursor: pointer;
      font-size: 1.6rem;

      @media (max-width: 768px) {
        font-size: 16px;
      }

      @media (max-width: 576px) {
        top: 0;
        transform: translateY(-33px);
        right: 0;
      }

      &:hover {
        color: $red-hover;
      }
    }
  }
}

label {
  font-size: 1.8rem;
  margin-bottom: 1.2rem;
  color: $grey-03;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}