
.section__lk-content-item-right:not(.folders-item) {
  width: 30rem;
  @media (max-width: 576px){
    width: 100%;
  }
}
.collapse {
  .section__lk-content-item {
    background: none!important;
  }
}
