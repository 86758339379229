
.orders-folders-separator {
  color: #1C75BC;
  font-size: 2.8rem;
  border-bottom: 2px solid #1C75BC;
  @media (max-width: 540px) {
    font-size: 28px;
  }
  margin-bottom: 20px;
}
