/**
 * select
 */
.chosen-container {
  .disabled & {
    pointer-events: none;

    &-single .chosen-single {
      background: #f2f2f2;
      color: #bdbdbd;

      div b {
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.99995 4.71006L2.45995 1.17006C2.27259 0.983805 2.01913 0.879264 1.75495 0.879264C1.49076 0.879264 1.23731 0.983805 1.04995 1.17006C0.95622 1.26302 0.881825 1.37362 0.831057 1.49548C0.780288 1.61734 0.75415 1.74804 0.75415 1.88006C0.75415 2.01207 0.780288 2.14277 0.831057 2.26463C0.881826 2.38649 0.95622 2.49709 1.04995 2.59006L5.28995 6.83006C5.38291 6.92378 5.49351 6.99818 5.61537 7.04895C5.73723 7.09972 5.86794 7.12585 5.99995 7.12585C6.13196 7.12585 6.26267 7.09972 6.38453 7.04895C6.50638 6.99818 6.61699 6.92378 6.70995 6.83006L10.9999 2.59006C11.0926 2.49662 11.166 2.3858 11.2157 2.26396C11.2655 2.14212 11.2907 2.01166 11.2899 1.88006C11.2907 1.74845 11.2655 1.61799 11.2157 1.49615C11.166 1.37431 11.0926 1.2635 10.9999 1.17006C10.8126 0.983805 10.5591 0.879263 10.2949 0.879263C10.0308 0.879263 9.77731 0.983805 9.58995 1.17006L5.99995 4.71006Z' fill='%23BDBDBD'/%3E%3C/svg%3E");
      }
    }
  }

  &-single {
    .chosen-single {
      display: flex;
      align-items: center;
      overflow: hidden;
      padding: 0 7rem 0 1.4rem;
      height: 4.8rem;
      border: 0;
      border-radius: 0;
      background: #F2F2F2;
      box-shadow: $box-shadow-default;
      color: #828282;
      font-size: 1.8rem;
      line-height: 24px;

      &:hover {
        box-shadow: $box-shadow-hover;
        background: #E0E0E0;
      }

      @media (max-width: 768px){
        padding: 0 70px 0 14px;
        height: 48px;
        font-size: 14px;
      }

      div {
        right: 1rem;
        width: 2.8rem;
        display: flex;
        align-items: center;

        @media (max-width: 768px){
          right: 10px;
          width: 28px;
        }

        b {
          width: 2.8rem;
          height: 2.8rem;
          border-radius: 50%;
          background: #F2F2F2 url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.99995 4.71006L2.45995 1.17006C2.27259 0.983805 2.01913 0.879264 1.75495 0.879264C1.49076 0.879264 1.23731 0.983805 1.04995 1.17006C0.95622 1.26302 0.881825 1.37362 0.831057 1.49548C0.780288 1.61734 0.75415 1.74804 0.75415 1.88006C0.75415 2.01207 0.780288 2.14277 0.831057 2.26463C0.881826 2.38649 0.95622 2.49709 1.04995 2.59006L5.28995 6.83006C5.38291 6.92378 5.49351 6.99818 5.61537 7.04895C5.73723 7.09972 5.86794 7.12585 5.99995 7.12585C6.13196 7.12585 6.26267 7.09972 6.38453 7.04895C6.50638 6.99818 6.61699 6.92378 6.70995 6.83006L10.9999 2.59006C11.0926 2.49662 11.166 2.3858 11.2157 2.26396C11.2655 2.14212 11.2907 2.01166 11.2899 1.88006C11.2907 1.74845 11.2655 1.61799 11.2157 1.49615C11.166 1.37431 11.0926 1.2635 10.9999 1.17006C10.8126 0.983805 10.5591 0.879263 10.2949 0.879263C10.0308 0.879263 9.77731 0.983805 9.58995 1.17006L5.99995 4.71006Z' fill='%231C75BC'/%3E%3C/svg%3E") no-repeat 50% 50%;
          background-size: 1.2rem auto;

          @media (max-width: 768px){
            width: 28px;
            height: 28px;
            background-size: 12px auto;
            background-color: $white;
          }
        }
      }

      abbr {
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 50%;
        background: #F2F2F2 url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.17511 6.99991L13.4251 1.75824C13.582 1.60132 13.6702 1.3885 13.6702 1.16658C13.6702 0.944659 13.582 0.73183 13.4251 0.57491C13.2682 0.417991 13.0554 0.329834 12.8334 0.329834C12.6115 0.329834 12.3987 0.417991 12.2418 0.57491L7.00011 5.82491L1.75845 0.57491C1.60153 0.417991 1.3887 0.329834 1.16678 0.329834C0.944861 0.329834 0.732032 0.417991 0.575112 0.57491C0.418192 0.73183 0.330036 0.944659 0.330036 1.16658C0.330036 1.3885 0.418192 1.60132 0.575112 1.75824L5.82511 6.99991L0.575112 12.2416C0.497005 12.319 0.43501 12.4112 0.392703 12.5128C0.350395 12.6143 0.328613 12.7232 0.328613 12.8332C0.328613 12.9433 0.350395 13.0522 0.392703 13.1537C0.43501 13.2553 0.497005 13.3474 0.575112 13.4249C0.652581 13.503 0.744749 13.565 0.846298 13.6073C0.947847 13.6496 1.05677 13.6714 1.16678 13.6714C1.27679 13.6714 1.38571 13.6496 1.48726 13.6073C1.58881 13.565 1.68098 13.503 1.75845 13.4249L7.00011 8.17491L12.2418 13.4249C12.3193 13.503 12.4114 13.565 12.513 13.6073C12.6145 13.6496 12.7234 13.6714 12.8334 13.6714C12.9435 13.6714 13.0524 13.6496 13.1539 13.6073C13.2555 13.565 13.3476 13.503 13.4251 13.4249C13.5032 13.3474 13.5652 13.2553 13.6075 13.1537C13.6498 13.0522 13.6716 12.9433 13.6716 12.8332C13.6716 12.7232 13.6498 12.6143 13.6075 12.5128C13.5652 12.4112 13.5032 12.319 13.4251 12.2416L8.17511 6.99991Z' fill='%231C75BC'/%3E%3C/svg%3E ") no-repeat 50% 50%;
        background-size: 1.4rem auto;
        top: 50%;
        transform: translateY(-50%);
        right: 4rem;

        @media (max-width: 768px){
          width: 28px;
          height: 28px;
          background-size: 14px auto;
          right: 40px;
          background-color: $white;
        }

        &:hover {
          background-position: 50% 50%;
          background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.17511 6.99991L13.4251 1.75824C13.582 1.60132 13.6702 1.3885 13.6702 1.16658C13.6702 0.944659 13.582 0.73183 13.4251 0.57491C13.2682 0.417991 13.0554 0.329834 12.8334 0.329834C12.6115 0.329834 12.3987 0.417991 12.2418 0.57491L7.00011 5.82491L1.75845 0.57491C1.60153 0.417991 1.3887 0.329834 1.16678 0.329834C0.944861 0.329834 0.732032 0.417991 0.575112 0.57491C0.418192 0.73183 0.330036 0.944659 0.330036 1.16658C0.330036 1.3885 0.418192 1.60132 0.575112 1.75824L5.82511 6.99991L0.575112 12.2416C0.497005 12.319 0.43501 12.4112 0.392703 12.5128C0.350395 12.6143 0.328613 12.7232 0.328613 12.8332C0.328613 12.9433 0.350395 13.0522 0.392703 13.1537C0.43501 13.2553 0.497005 13.3474 0.575112 13.4249C0.652581 13.503 0.744749 13.565 0.846298 13.6073C0.947847 13.6496 1.05677 13.6714 1.16678 13.6714C1.27679 13.6714 1.38571 13.6496 1.48726 13.6073C1.58881 13.565 1.68098 13.503 1.75845 13.4249L7.00011 8.17491L12.2418 13.4249C12.3193 13.503 12.4114 13.565 12.513 13.6073C12.6145 13.6496 12.7234 13.6714 12.8334 13.6714C12.9435 13.6714 13.0524 13.6496 13.1539 13.6073C13.2555 13.565 13.3476 13.503 13.4251 13.4249C13.5032 13.3474 13.5652 13.2553 13.6075 13.1537C13.6498 13.0522 13.6716 12.9433 13.6716 12.8332C13.6716 12.7232 13.6498 12.6143 13.6075 12.5128C13.5652 12.4112 13.5032 12.319 13.4251 12.2416L8.17511 6.99991Z' fill='%23ED1B2F'/%3E%3C/svg%3E ");
        }
      }
    }

    .chosen-drop {
      margin-top: 1.2rem;
      border-radius: 0;
      border: 0;
      background: #fff;
      box-shadow: $box-shadow-hover;

      @media (max-width: 768px){
        margin-top: 12px;
      }
    }
  }

  &-active {
    &.chosen-with-drop {
      .chosen-single {
        border: 0;
        background: #F2F2F2;
        box-shadow: $box-shadow-hover;

        div b {
          background-position: 50% 50%;
          transform: rotate(180deg);
        }
      }
    }
  }

  &-single {
    .chosen-single-with-deselect span {
      margin-right: 8px;
    }
  }

  .chosen-results {
    margin: 0;
    padding: 0;
    max-height: 7.4rem * 4;

    @media (max-width: 768px){
      max-height: 48px * 4;
    }

    li {
      display: none;
      margin: 0;
      padding: 0 2.4rem;
      font-size: 1.8rem;

      @media (max-width: 768px){
        padding: 0 24px;
        font-size: 14px;
      }

      &.active-result {
        display: flex;
        height: 7.4rem;
        align-items: center;
        color: $blue-03;

        &:not(.result-selected):hover,
        &.highlighted {
          color: $white;
          background: $blue-02;
        }
      }

      &.result-selected {
        background: $blue-02 url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.7099 1.2101C14.617 1.11638 14.5064 1.04198 14.3845 0.991213C14.2627 0.940445 14.132 0.914307 13.9999 0.914307C13.8679 0.914307 13.7372 0.940445 13.6154 0.991213C13.4935 1.04198 13.3829 1.11638 13.29 1.2101L5.83995 8.6701L2.70995 5.5301C2.61343 5.43687 2.49949 5.36355 2.37463 5.31435C2.24978 5.26514 2.11645 5.24101 1.98227 5.24334C1.84809 5.24566 1.71568 5.27438 1.5926 5.32788C1.46953 5.38137 1.35819 5.45858 1.26495 5.55511C1.17171 5.65163 1.0984 5.76557 1.04919 5.89042C0.999989 6.01528 0.975859 6.1486 0.97818 6.28278C0.980502 6.41696 1.00923 6.54937 1.06272 6.67245C1.11622 6.79553 1.19343 6.90687 1.28995 7.0001L5.12995 10.8401C5.22291 10.9338 5.33351 11.0082 5.45537 11.059C5.57723 11.1098 5.70794 11.1359 5.83995 11.1359C5.97196 11.1359 6.10267 11.1098 6.22453 11.059C6.34639 11.0082 6.45699 10.9338 6.54995 10.8401L14.7099 2.68011C14.8115 2.58646 14.8925 2.47281 14.9479 2.34631C15.0033 2.21981 15.0319 2.08321 15.0319 1.94511C15.0319 1.807 15.0033 1.6704 14.9479 1.5439C14.8925 1.4174 14.8115 1.30375 14.7099 1.2101Z' fill='white'/%3E%3C/svg%3E") no-repeat calc(100% - 3.1rem) 50%;
        background-size: 1.6rem auto;
        color: $white;
        padding-right: 4.5rem;

        @media (max-width: 768px){
          padding-right: 45px;
          background-size: 16px auto;
        }
      }
    }
  }
}

select {
  display: block;
  appearance: none;
  width: 100%;
  border: 0;
  background-color: $grey-06;
  background-position: calc(100% - 2rem) 50%;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.99995 4.71018L2.45995 1.17018C2.27259 0.983927 2.01913 0.879386 1.75495 0.879386C1.49076 0.879386 1.23731 0.983927 1.04995 1.17018C0.95622 1.26314 0.881825 1.37374 0.831057 1.4956C0.780288 1.61746 0.75415 1.74817 0.75415 1.88018C0.75415 2.01219 0.780288 2.1429 0.831057 2.26476C0.881826 2.38661 0.95622 2.49722 1.04995 2.59018L5.28995 6.83018C5.38291 6.92391 5.49351 6.9983 5.61537 7.04907C5.73723 7.09984 5.86794 7.12598 5.99995 7.12598C6.13196 7.12598 6.26267 7.09984 6.38453 7.04907C6.50638 6.9983 6.61699 6.92391 6.70995 6.83018L10.9999 2.59018C11.0926 2.49674 11.166 2.38592 11.2157 2.26408C11.2655 2.14225 11.2907 2.01178 11.2899 1.88018C11.2907 1.74857 11.2655 1.61811 11.2157 1.49627C11.166 1.37443 11.0926 1.26362 10.9999 1.17018C10.8126 0.983927 10.5591 0.879386 10.2949 0.879386C10.0308 0.879386 9.77731 0.983927 9.58995 1.17018L5.99995 4.71018Z' fill='%231C75BC'/%3E%3C/svg%3E");
  outline: 0;
  padding: 1.2rem 3rem 1.2rem 1.4rem;
  color: $grey-03;
  font-size: 1.8rem;

  @media (max-width: 768px){
    font-size: 18px;
    background-color: $grey-06;
    padding: 12px 40px 12px 14px;
  }

  .disabled & {
    pointer-events: none;
    color: #BDBDBD;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.99995 4.71018L2.45995 1.17018C2.27259 0.983927 2.01913 0.879386 1.75495 0.879386C1.49076 0.879386 1.23731 0.983927 1.04995 1.17018C0.95622 1.26314 0.881825 1.37374 0.831057 1.4956C0.780288 1.61746 0.75415 1.74817 0.75415 1.88018C0.75415 2.01219 0.780288 2.1429 0.831057 2.26476C0.881826 2.38661 0.95622 2.49722 1.04995 2.59018L5.28995 6.83018C5.38291 6.92391 5.49351 6.9983 5.61537 7.04907C5.73723 7.09984 5.86794 7.12598 5.99995 7.12598C6.13196 7.12598 6.26267 7.09984 6.38453 7.04907C6.50638 6.9983 6.61699 6.92391 6.70995 6.83018L10.9999 2.59018C11.0926 2.49674 11.166 2.38592 11.2157 2.26408C11.2655 2.14225 11.2907 2.01178 11.2899 1.88018C11.2907 1.74857 11.2655 1.61811 11.2157 1.49627C11.166 1.37443 11.0926 1.26362 10.9999 1.17018C10.8126 0.983927 10.5591 0.879386 10.2949 0.879386C10.0308 0.879386 9.77731 0.983927 9.58995 1.17018L5.99995 4.71018Z' fill='%23BDBDBD'/%3E%3C/svg%3E");
  }
}

/**
 * vue-select
 */
.v-select {
  padding: 0!important;
  height: auto;
  border: 0!important;
  border-radius: 0!important;
  background: #F2F2F2!important;
  box-shadow: 0 1.6rem 2.4rem rgba(0, 0, 0, 0.08), 0 0.2rem 0.6rem rgba(0, 0, 0, 0.04), 0 0 0.1rem rgba(0, 0, 0, 0.04)!important;
  color: #828282!important;
  font-size: 1.8rem!important;
  line-height: 24px!important;
  @media (max-width: 768px) {
    line-height: 24px!important;
    height: 48px!important;
    font-size: 14px!important;
    align-items: center;
  }
  &.searchable {
    height: 42px!important;
  }
  .dropdown-toggle, &:not(.searchable) .selected-tag {
    border: none!important;
    height: 100%!important;
    padding: 0!important;
    margin: 0!important;
  }
  &:not(.searchable) .selected-tag {
    padding: 1rem 0 1rem 1.4rem!important;
    z-index: 2;
    width: 100%;
  }
  .dropdown-menu {
    margin-top: 1.2rem!important;
    border-radius: 0!important;
    border: 0!important;
    padding: 0!important;
    left: 0!important;
    background: #fff!important;
    box-shadow: 0 1.6rem 2.4rem rgba(0, 0, 0, 0.2), 0 0.2rem 0.6rem rgba(0, 0, 0, 0.04), 0 0 0.1rem rgba(0, 0, 0, 0.04)!important;
    li {
      overflow: hidden;
      margin: 0;
      font-size: 1.8rem;
      @media (max-width: 768px) {
        line-height: 24px!important;
        font-size: 14px!important;
      }
      &:nth-of-type(odd) {
        background-color: #F2F2F2;
      }

      a {
        min-height: 7.4rem!important;
        color: $blue-03!important;
        padding: 2.4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        div {
          display: flex;
          justify-content: left;
          align-items: center;
          min-height: 2.4rem;
          white-space: normal;
          .course-cut-date {
            width: 16.9rem;
          }
        }
        .text-dark, .text-grey {
          padding-top: 0.8rem!important;
        }

        .text-grey {
          color: #828282;
          font-size: 1.8rem;
          @media (max-width: 768px) {
            font-size: 14px;
          }
        }

        svg {
          fill: #828282;
          margin-top: -0.5rem;
          transform: scale(0.8);
          width: 2.4rem;
          height: 2.4rem;
          @media (max-width: 768px) {
            width: 24px;
            height: 24px;
            margin-top: -5px;
          }
        }

        &:not(.result-selected):hover,
        &.highlighted {
          color: $white!important;
          background: $blue-02!important;
          .text-grey {
            color: $white!important;
          }
          svg {
            fill: $white!important;
          }
        }
      }
      &.highlight {
        a {
          background-size: 1.6rem auto!important;
          color: #ffffff!important;
          padding-right: 4.5rem!important;
          .text-grey, .text-dark {
            color: $white!important;
          }
          svg {
            fill: $white!important;
          }
          .selected-type {
            color: #fff;
          }
        }
      }
    }
  }
  &.unsearchable {
    input[type=search] {
      opacity: 0!important;
      position: absolute;
    }
  }
  .dropdown-toggle:hover {
    box-shadow: 0 1.6rem 2.4rem rgba(0, 0, 0, 0.2), 0 0.2rem 0.6rem rgba(0, 0, 0, 0.04), 0 0 0.1rem rgba(0, 0, 0, 0.04) !important;
    background: #E0E0E0 !important;
  }
  .vs__actions {
    padding-right: 1.4rem!important;
    .open-indicator:before {
      border-color: #1C75BC!important;
    }
  }
  .selected {
    &-option {
      display: flex;
      &-type {
        color: $blue-02;
        font-weight: 700;
        margin-right: 1rem;
        white-space: nowrap;
        z-index: 0;
      }
    }
  }
  .vs__selected-options {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.v-select.single.open{
  &:not(.searchable) {
    .selected-tag {
      position: relative!important;
    }
  }
}

.v-select-centered.v-select.single {
  &:not(.searchable) {
    .selected-tag{
      overflow: hidden!important;
      text-overflow: ellipsis!important;
      display: -webkit-box!important;
      line-clamp: 2!important;
      -webkit-line-clamp: 2!important;
      -webkit-box-orient: vertical!important;
    }
  }
}

.v-select .dropdown-toggle .clear {
  margin-top: 7px;
}