/**
 * lk.scss
 */
.section__lk {
  align-items: flex-start;

  @media screen and (min-width: 1731px) {
    padding-top: 21.3rem;
  }

  @media (max-width: 576px) {
    .title-with-icon {
      margin-bottom: 40px !important;
    }
  }

  &-wrapper {
    display: flex;
    align-items: stretch;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .separator {
    height: 2px;
    background: #BDBDBD;
    margin: 48px 0;
    line-height: 1;
  }

  h3 {
    line-height: 1;
    margin-bottom: 32px;
  }

  &-menu {
    flex: 0 0 31.2rem;
    max-width: 31.2rem;

    @media (max-width: 768px) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 24px;
    }

    nav {
      @media (max-width: 768px) {
        margin-right: -15px;
        overflow-y: hidden;
        overflow-x: auto;

        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */

        &::-webkit-scrollbar {
          background: transparent; /* Chrome/Safari/Webkit */
          width: 0;
          height: 0;
        }
      }

      @media (max-width: 576px) {
        margin-right: 0;
        overflow: visible;
        position: relative;
        z-index: 10;

        &.open {
          .section__lk-menu-mobile {
            background: $blue-02;

            .toggle {
              background: #0A4C92;

              &:after {
                transform: scale(1, -1);
              }
            }
          }

          ul {
            opacity: 1;
            height: auto;
          }
        }
      }

      li {
        background: $blue-03;
        transition: .3s ease-in-out;

        @media (max-width: 576px) {
          &.active {
            display: none;
          }
        }

        > a {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 1.6rem 3.2rem;

          color: $white;
          font-size: 1.8rem;
          font-weight: 400;
          min-height: 9.6rem;
          transition: .3s ease-in-out;
          text-decoration: none;

          &.section__lk-menu-mobile {
            position: relative;
            background: $blue-02;

            .toggle {
              width: 52px;
              height: 100%;
              position: absolute;
              top: 0;
              right: 0;
              content: '';
              background: $blue-03;
              cursor: pointer;

              &:after {
                position: absolute;
                content: '';
                top: 50%;
                margin-top: -4px;
                right: 20px;
                width: 12px;
                height: 8px;
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.99995 4.71018L2.45995 1.17018C2.27259 0.983927 2.01913 0.879386 1.75495 0.879386C1.49076 0.879386 1.23731 0.983927 1.04995 1.17018C0.95622 1.26314 0.881825 1.37374 0.831057 1.4956C0.780288 1.61746 0.75415 1.74817 0.75415 1.88018C0.75415 2.01219 0.780288 2.1429 0.831057 2.26476C0.881826 2.38661 0.95622 2.49722 1.04995 2.59018L5.28995 6.83018C5.38291 6.92391 5.49351 6.9983 5.61537 7.04907C5.73723 7.09984 5.86794 7.12598 5.99995 7.12598C6.13196 7.12598 6.26267 7.09984 6.38453 7.04907C6.50638 6.9983 6.61699 6.92391 6.70995 6.83018L10.9999 2.59018C11.0926 2.49674 11.166 2.38592 11.2157 2.26408C11.2655 2.14225 11.2907 2.01178 11.2899 1.88018C11.2907 1.74857 11.2655 1.61811 11.2157 1.49627C11.166 1.37443 11.0926 1.26362 10.9999 1.17018C10.8126 0.983927 10.5591 0.879386 10.2949 0.879386C10.0308 0.879386 9.77731 0.983927 9.58995 1.17018L5.99995 4.71018Z' fill='white'/%3E%3C/svg%3E");
              }
            }
          }

          @media (max-width: 768px) {
            font-size: 16px;
            min-height: 72px;
            line-height: 1.3;
          }
        }

        &.active {
          background: $blue-02;
        }

        &.current {
          @media (max-width: 576px) {
            display: none;
          }
        }
      }


      img, svg {
        margin-right: 2.4rem;

        @media (max-width: 768px) {
          margin-right: 16px;
          width: 32px;
          height: 32px;
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;

      @media (max-width: 768px) {
        flex-direction: row;
      }

      @media (max-width: 768px) and (min-width: 577px) {
        height: 180px;
        align-items: flex-start;
      }

      @media (max-width: 576px) {
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: $white;
        height: 0;
        opacity: 0;
        overflow: hidden;
        transition: .3s ease-in-out;
      }

      li {

        &:not(:last-child) {
          margin-bottom: 1px;
        }

        @media (max-width: 768px) {
          flex: 0 0 33.33%;
          max-width: 33.333%;

          &:not(:last-child) {
            margin-bottom: 0;
            margin-right: 1px;
          }
        }

        @media (max-width: 576px) {
          flex: 0 0 100%;
          max-width: 100%;
          height: auto;

          &:not(:last-child) {
            margin-bottom: 1px;
            margin-right: 0;
          }
        }
      }
    }

    li {
      margin: 0;
      padding: 0;
      list-style: none;
      position: relative;

      &.has-child {
        position: relative;

        .section__lk-submenu {
          display: block;
          visibility: hidden;
          opacity: 0;
          height: 0;
          transition: .2s;
          margin-top: -16px;

          @media (min-width: 769px) {
            margin-top: 0;
            padding-bottom: 0;
          }
        }

        &.active {
          height: auto;
          z-index: 1;

          .section__lk-submenu {
            visibility: visible;
            height: auto;
            opacity: 1;

            @media (min-width: 769px) {
              margin-top: -2.2rem;
              padding-bottom: 2.6rem;
            }
          }

          .indicator {
            transform: scale(1, -1);
          }
        }

        .indicator {
          position: absolute;
          right: 1.5rem;
          top: 3.2rem;
          transition: .3s ease-in-out;
          //width: 12px;
          //height: 8px;
          width: 3rem;
          height: 3rem;
          cursor: pointer;
          background-size: 1.2rem auto;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.00001 4.71018L2.46001 1.17018C2.27265 0.983927 2.0192 0.879386 1.75501 0.879386C1.49082 0.879386 1.23737 0.983927 1.05001 1.17018C0.956281 1.26314 0.881887 1.37374 0.831118 1.4956C0.780349 1.61746 0.754211 1.74817 0.754211 1.88018C0.754211 2.01219 0.780349 2.1429 0.831118 2.26476C0.881887 2.38661 0.956281 2.49722 1.05001 2.59018L5.29001 6.83018C5.38297 6.92391 5.49357 6.9983 5.61543 7.04907C5.73729 7.09984 5.868 7.12598 6.00001 7.12598C6.13202 7.12598 6.26273 7.09984 6.38459 7.04907C6.50645 6.9983 6.61705 6.92391 6.71001 6.83018L11 2.59018C11.0927 2.49674 11.166 2.38592 11.2158 2.26408C11.2655 2.14225 11.2908 2.01178 11.29 1.88018C11.2908 1.74857 11.2655 1.61811 11.2158 1.49627C11.166 1.37443 11.0927 1.26362 11 1.17018C10.8126 0.983927 10.5592 0.879386 10.295 0.879386C10.0308 0.879386 9.77737 0.983927 9.59001 1.17018L6.00001 4.71018Z' fill='white'/%3E%3C/svg%3E");

          @media (max-width: 768px) {
            right: 15px;
            top: 19px;
            width: 30px;
            height: 30px;
            background-size: 12px auto;
          }
        }
      }
    }

    &-mobile {
      min-height: 9.6rem;
      transition: .3s ease-in-out;
      text-decoration: none;
      position: relative;
      background: $blue-02;
      width: 100%;
      padding: 16px 18px;

      .toggle {
        width: 52px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        content: '';
        background: $blue-03;
        cursor: pointer;

        &:after {
          position: absolute;
          content: '';
          top: 50%;
          margin-top: -4px;
          right: 20px;
          width: 12px;
          height: 8px;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.99995 4.71018L2.45995 1.17018C2.27259 0.983927 2.01913 0.879386 1.75495 0.879386C1.49076 0.879386 1.23731 0.983927 1.04995 1.17018C0.95622 1.26314 0.881825 1.37374 0.831057 1.4956C0.780288 1.61746 0.75415 1.74817 0.75415 1.88018C0.75415 2.01219 0.780288 2.1429 0.831057 2.26476C0.881826 2.38661 0.95622 2.49722 1.04995 2.59018L5.28995 6.83018C5.38291 6.92391 5.49351 6.9983 5.61537 7.04907C5.73723 7.09984 5.86794 7.12598 5.99995 7.12598C6.13196 7.12598 6.26267 7.09984 6.38453 7.04907C6.50638 6.9983 6.61699 6.92391 6.70995 6.83018L10.9999 2.59018C11.0926 2.49674 11.166 2.38592 11.2157 2.26408C11.2655 2.14225 11.2907 2.01178 11.2899 1.88018C11.2907 1.74857 11.2655 1.61811 11.2157 1.49627C11.166 1.37443 11.0926 1.26362 10.9999 1.17018C10.8126 0.983927 10.5591 0.879386 10.2949 0.879386C10.0308 0.879386 9.77731 0.983927 9.58995 1.17018L5.99995 4.71018Z' fill='white'/%3E%3C/svg%3E");
        }
      }

      .section__lk-submenu {
        display: block;
        padding: 0 0 5px 49px;
        margin-top: 7px;
      }

      span {
        display: flex;
        align-items: center;
        color: $white;
        font-size: 16px;
        line-height: 1.3;
        font-weight: 400;

        &:hover {
          color: $white;
          text-decoration: none;
        }
      }

    }
  }


  &-submenu {
    display: none;
    margin-top: -1rem;
    padding: 0 0 1.6rem calc(32px + 5.6rem);

    @media (max-width: 768px) {
      padding: 0 0 16px 66px;
    }

    .active & {
      display: block;
    }

    div:not(:last-child) {
      margin-bottom: 5px;
    }

    a {
      padding-right: 3.2rem;
      background-size: 1.6rem auto;
      background-repeat: no-repeat;
      background-position: 100% 50%;
      font-size: 1.6rem;
      color: $white;
      opacity: 0.5;
      text-decoration: none;

      &:hover {
        opacity: 1;
      }

      @media (max-width: 768px) {
        background-size: 16px auto;
        font-size: 13px;
        padding-right: 24px;
      }

      &.active {
        opacity: 1;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath class='circle' d='M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z' fill='%23#{str-slice('#{$red}', 2)}'/%3E%3Cpath class='arrow' d='M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z' fill='%23#{str-slice('#{$white}', 2)}'/%3E%3C/svg%3E");
      }
    }
  }

  &-text {
    margin-top: 3.6rem;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.8rem;
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 32px;
    }
    @media (max-width: 576px) {
      font-size: 16px;
      line-height: 32px;
    }
  }

  &-content {
    flex: 1;
    padding: 3rem 4.8rem 0;

    @media (max-width: 768px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 24px;
    }

    @media (max-width: 576px) {
      padding: 16px;
    }

    @media (max-width: 768px) and (min-width: 577px) {
      margin-top: -120px;
    }
    &__left {
      flex: 1;
      .form-item_groupped > label + * {
        max-width: calc(100% - 20.8rem);
        flex: 0 0 calc(100% - 20.8rem);
      }
    }
    &__right {
      width: 28.1rem;
      padding-left: 4.8rem;
      &__avatar {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 14.8rem;
        height: 14.8rem;
        border-radius: 37%;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
    &-items {
      margin: 5rem -4.8rem 0;
      .icon {
        width: 3.2rem;
        height: 3.2rem;
      }
      @media (max-width: 768px) {
        margin: 50px -16px 0;
        .icon {
          width: 35px!important;
          height: 35px!important;
        }
        .calendar {
          width: 24px!important;
          height: 24px!important;
        }
      }
    }
    &-item {
      min-height: 13.6rem;
      display: flex;
      padding: 2.4rem 4.8rem;
      cursor: pointer;
      @media (max-width: 576px) {
        display: block;
      }
      &:nth-of-type(odd) {
        background-color: #F2F2F2;
      }
      &-left {
        flex: 1;
        display: flex;
        @media (max-width: 576px) {
          display: block;
          width: auto;
        }
        .icon {
          background-size: contain;
          background-repeat: no-repeat;
          flex: 0 0 35px;
          &.video {
            background-image: url('/pic/profile/icon-video.svg');
          }
          &.tutorial {
            background-image: url('/pic/profile/icon-tutorial.svg');
          }
          &.test {
            background-image: url('/pic/profile/icon-test.svg');
          }
        }
      }
      &-separator {
        width: 0.2rem;
        background: $blue-04;
        height: 100%;
        margin: 0 3.2rem;
        @media (max-width: 576px) {
          display: none;
        }
      }
      &-data {
        font-size: 1.8rem;
        line-height: 2.4rem;
        @media (max-width: 768px) {
          font-size: 16px!important;
          line-height: 22px!important;
        }
        &-title {
          color: $secondary;
          @media (max-width: 576px) {
            margin-top: 16px;
          }
        }
        &-content {
          color: $blue-03;
          padding-right: 3.2rem;
          .btn {
            margin-top: 1.2rem;
            padding: 0.8rem 3rem;
            height: auto;
          }
        }
      }
      &-right {
        display: flex;
        align-items: center;
        border-left: 2px solid $blue-04;
        padding-left: 2.6rem;
        width: 24.7rem;
        @media (max-width: 576px) {
          width: auto;
          border-left: none;
          padding-left: 0;
          margin-top: 16px;
        }
        img {
          width: 2rem;
          margin-right: 1rem;
          @media (max-width: 768px) {
            width: 24px;
            margin-right: 8px;
          }
        }
      }
      &-subheader {
        color: $blue-02;
      }
    }
  }

  &-title {
    font-size: 3.2rem;
    line-height: 3.6rem;
    font-weight: 400;
    margin-bottom: 3rem;

    @media (max-width: 768px) {
      font-size: 24px;
      margin-bottom: 24px;
    }

    &-right {
      font-style: normal;
      font-weight: 400;
      font-size: 2.4rem;
      line-height: 2.4rem;
    }

    @media (max-width: 768px) {
      margin-bottom: 16px;
    }
  }

  &-notes {
    margin: -2rem 0;
  }
  &-subtitle {
    margin: 0 -4.8rem;
    padding: 2rem  4.8rem;
    background: $blue-03;
    color: $white;
    font-size: 2.4rem;
    line-height: 1;
    font-weight: 400;

    @media (max-width: 768px) {
      margin: 0 -24px;
      padding: 12px 24px;
      font-size: 18px;
    }

    @media (max-width: 576px) {
      padding: 12px 16px;
      margin: 0 -16px;
    }
  }

  &-empty {
    margin-top: 11.2rem;
    text-align: center;
    padding-top: 19rem;
    font-weight: 400;
    font-size: 2.4rem;
    background: no-repeat 50% 0 url("data:image/svg+xml,%3Csvg width='156' height='156' viewBox='0 0 156 156' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M101.156 73.7344V30.0992L71.0571 0H0V137.719H37.1719V156H156V73.7344H101.156ZM73.7344 15.604L85.5523 27.4219H73.7344V15.604ZM9.14062 128.578V9.14062H64.5938V36.5625H92.0156V73.7344H37.1719V128.578H9.14062ZM146.859 146.859H46.3125V82.875H146.859V146.859Z' fill='%23BDBDBD'/%3E%3Cpath d='M60.0233 139.612L78.3046 121.33L96.5858 139.612L114.867 121.33L133.148 139.612L139.612 133.148L121.33 114.867L139.612 96.5859L133.148 90.1226L96.5858 126.685L60.0233 90.1226L53.56 96.5859L71.8413 114.867L53.56 133.148L60.0233 139.612Z' fill='%23BDBDBD'/%3E%3Cpath d='M18.8906 37.1719H55.4531V46.3125H18.8906V37.1719Z' fill='%23BDBDBD'/%3E%3Cpath d='M18.8906 55.4531H82.875V64.5938H18.8906V55.4531Z' fill='%23BDBDBD'/%3E%3C/svg%3E ");
    background-size: 15.6rem auto;

    @media (max-width: 768px) {
      background-size: 10rem auto;
      margin-top: 112px;
      padding-top: 118px;
      font-size: 18px;
    }
  }

  &-label {
    color: #828282;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 2.4rem;
    font-weight: 400;
    display: block;
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 32px;
      margin-bottom: 16px;
    }
  }

  &-under-label {
    display: flex;
    flex-flow: wrap;
    margin-top: 3.6rem;
    font-size: 1.8rem;
    line-height: 2.4rem;
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 32px;
      align-items: center;
    }
    @media (max-width: 576px) {
      text-align: left;
    }
    > div {
      display: flex;
      align-items: center;
      &.section__lk-under-label-link {
        @media (max-width: 768px) {
          padding-top: 24px;
          margin-top: 24px;
          border-top: 2px solid #DBDBDD!important;
          display: block!important;
          width: 100%;
        }
      }
    }
    &-time {
      justify-content: start;
      padding-left: 1.4rem;
      .icon {
        margin-right: 0.6rem
      }
      @media (max-width: 576px) {
        font-size: 16px;
        line-height: 24px;
        .icon {
          margin-right: 1.4rem;
          display: inline;
        }
      }
    }
    &-separator {
      justify-content: start;
      width: 2px;
      height: 2.4rem;
      background: $blue-04;
      margin: 0 2.4rem;
    }
    &-link {
      justify-content: start;
      a {
        color: $blue-03;
        text-decoration: underline;
      }
    }
    &-seminar {
      &-data {
        flex: 1;
        padding-left: 1.6rem;
        align-items: center;
      }
      &-count {
        .icon {
          margin-right: 0.6rem
        }
      }
      &-city {
        padding-left: 5.1rem;
        .icon {
          margin-right: 1rem
        }
      }
    }
  }

  &-order {
    margin: 0 -4.8rem;
    padding: 3.6rem 4.8rem;
    display: flex;
    flex-flow: wrap;
    color: $text;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
    align-items: flex-start;

    @media (max-width: 768px) {
      margin: 0 -24px;
      padding: 24px;
      flex-direction: column;
    }

    @media (max-width: 768px) {
      margin: 0 -16px;
      padding: 24px 16px;
    }

    &:hover {
      text-decoration: none;
      color: $text;
    }

    &:nth-child(2n){
      background: $grey-06;
    }

    .label {
      margin-left: -3rem;
      padding-left: 3rem;
      background-size: 2rem auto;
      background-repeat: no-repeat;
      background-position: 0 50%;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $blue-02;
      font-weight: 400;
      margin-bottom: 0.8rem;

      @media (max-width: 768px) {
        margin-left: -30px;
        padding-left: 30px;
        font-size: 16px;
        background-size: 20px auto;
        line-height: 30px;
      }
    }

    &-title {
      padding-right: 6.4rem;
      padding-left: 3rem;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 1.3;
      flex-basis: 80%;

      @media (max-width: 768px) {
        padding-left: 30px;
        font-size: 16px;
        flex-basis: auto;
      }

      @media (max-width: 576px) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 16px;
      }

      .label {
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V14C0 14.7956 0.316071 15.5587 0.87868 16.1213C1.44129 16.6839 2.20435 17 3 17H5.59L8.29 19.71C8.38344 19.8027 8.49426 19.876 8.61609 19.9258C8.73793 19.9755 8.86839 20.0008 9 20C9.23834 20 9.46886 19.9149 9.65 19.76L12.87 17H15C15.7956 17 16.5587 16.6839 17.1213 16.1213C17.6839 15.5587 18 14.7956 18 14V3C18 2.20435 17.6839 1.44129 17.1213 0.87868C16.5587 0.316071 15.7956 0 15 0ZM16 14C16 14.2652 15.8946 14.5196 15.7071 14.7071C15.5196 14.8946 15.2652 15 15 15H12.5C12.2617 15 12.0311 15.0851 11.85 15.24L9.05 17.64L6.71 15.29C6.61656 15.1973 6.50574 15.124 6.38391 15.0742C6.26207 15.0245 6.13161 14.9992 6 15H3C2.73478 15 2.48043 14.8946 2.29289 14.7071C2.10536 14.5196 2 14.2652 2 14V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H15C15.2652 2 15.5196 2.10536 15.7071 2.29289C15.8946 2.48043 16 2.73478 16 3V14Z' fill='%231C75BC'/%3E%3C/svg%3E");
      }
    }

    &-description {
      color: #828282;
      font-weight: 400;
    }

    &-date-and-status {
      display: flex;
      flex-grow: 2;
      justify-content: space-between;
      flex-basis: 20%;
      @media (max-width: 768px) {
        flex-basis: auto;
        width: 100%;
      }
    }

    &-date {
      padding-right: 2rem;
      padding-left: 3rem;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 1.3;
      flex-grow: 1;

      @media (max-width: 768px) {
        padding-left: 30px;
        font-size: 16px;
      }

      @media (max-width: 576px) {
        flex: 1;
        max-width: 100%;
      }

      .label {
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.0981 10.6338L11 9.42285V5C11 4.73478 10.8946 4.48043 10.7071 4.29289C10.5196 4.10536 10.2652 4 10 4C9.73479 4 9.48043 4.10536 9.2929 4.29289C9.10536 4.48043 9 4.73478 9 5V10C8.99996 10.1756 9.04614 10.3481 9.13391 10.5001C9.22168 10.6522 9.34795 10.7784 9.5 10.8662L12.0981 12.3662C12.2119 12.432 12.3375 12.4746 12.4678 12.4918C12.598 12.509 12.7304 12.5004 12.8573 12.4664C12.9842 12.4324 13.1032 12.3737 13.2074 12.2938C13.3117 12.2138 13.3991 12.114 13.4648 12.0003C13.5305 11.8865 13.5731 11.7608 13.5902 11.6306C13.6073 11.5003 13.5986 11.368 13.5646 11.2411C13.5305 11.1142 13.4718 10.9952 13.3918 10.891C13.3117 10.7868 13.212 10.6994 13.0981 10.6338ZM10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34874 18.9425 4.80691 17.0678 2.93219C15.1931 1.05746 12.6513 0.00294858 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C17.9976 12.121 17.1539 14.1544 15.6542 15.6542C14.1544 17.1539 12.121 17.9976 10 18Z' fill='%231C75BC'/%3E%3C/svg%3E");
      }
    }

    &-status {
      align-self: center;
    }
  }

  &-course {
    margin: 0 -4.8rem;
    padding: 2rem 4.8rem;
    display: flex;
    flex-flow: wrap;
    color: $text;
    align-items: center;

    @media (max-width: 768px) {
      margin: 0 -24px;
      padding: 24px;
    }

    @media (max-width: 576px) {
      margin: 0 -16px;
      padding: 24px 16px;
    }

    &:nth-child(2n){
      background: $grey-06;
    }

    &-icon {
      flex: 0 0 3.2rem;
      max-width: 3.2rem;

      @media (max-width: 768px) {
        flex: 0 0 32px;
        max-width: 32px;
      }

      span {
        display: block;
        width: 3.2rem;
        height: 3.2rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 15.9596C13.5876 15.9596 11.625 17.9219 11.625 20.334C11.625 22.746 13.5876 24.7083 16 24.7083C18.4124 24.7083 20.375 22.746 20.375 20.334C20.375 17.9219 18.4124 15.9596 16 15.9596ZM16 22.8337C14.6215 22.8337 13.5 21.7123 13.5 20.334C13.5 18.9557 14.6215 17.8344 16 17.8344C17.3785 17.8344 18.5 18.9558 18.5 20.334C18.5 21.7123 17.3785 22.8337 16 22.8337Z' fill='%23002E5E'/%3E%3Cpath d='M32 0H0V21.2714H5.27981L0.0161875 24.5531L4.14412 27.1378L4.61019 32L11.6905 27.5807L13.6644 27.5222L16 29.3311L18.3357 27.5222L20.3095 27.5807L27.3898 32L27.8559 27.1377L31.9838 24.553L26.7201 21.2714H32V0ZM5.92244 26.0391L3.55437 24.5564L7.89612 21.8496L7.44088 23.1154L9.88644 24.7745L10.3106 26.2319L6.18756 28.8054L5.92244 26.0391ZM17.7191 25.6284L16 26.9597L14.2809 25.6284L12.1066 25.6928L11.4981 23.6023L9.69763 22.3809L10.4338 20.3341L9.69763 18.2873L11.4981 17.0658L12.1066 14.9753L14.2809 15.0397L16 13.7083L17.7191 15.0396L19.8934 14.9753L20.5019 17.0658L22.3024 18.2872L21.5662 20.334L22.3024 22.3808L20.5019 23.6022L19.8934 25.6928L17.7191 25.6284ZM26.0776 26.0391L25.8124 28.8054L21.6894 26.2319L22.1136 24.7745L24.5591 23.1154L24.1039 21.8496L28.4456 24.5564L26.0776 26.0391ZM30.125 19.3966H23.8959L24.5591 17.5526L22.1135 15.8935L21.2883 13.0583L18.3356 13.1458L16 11.3369L13.6643 13.1458L10.7117 13.0584L9.8865 15.8936L7.44094 17.5527L8.10413 19.3967H1.875V1.87475H30.125V19.3966Z' fill='%23002E5E'/%3E%3Cpath d='M5.68066 3.87427H26.3194V5.74902H5.68066V3.87427Z' fill='%23002E5E'/%3E%3Cpath d='M9.43066 7.62402H22.5693V9.49877H9.43066V7.62402Z' fill='%23002E5E'/%3E%3C/svg%3E");

        @media (max-width: 768px) {
          width: 32px;
          height: 32px;
        }
      }
    }


    &-title {
      flex: 1;
      padding: 0 3.2rem;
      margin: 0 3.2rem;
      border-left: 2px solid #BDBDBD;
      border-right: 2px solid #BDBDBD;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 1.3;
      color: $blue-03;

      .label {
        margin-left: -3rem;
        padding-left: 3rem;
        background-size: 2rem auto;
        background-repeat: no-repeat;
        background-position: 0 50%;
        font-size: 1.8rem;
        color: $red;
        font-weight: 400;
        margin-bottom: .8rem;

        @media (max-width: 768px) {
          font-size: 16px;
          margin-bottom: 0;
        }
      }

      @media (max-width: 768px) {
        padding: 0 24px;
        margin: 0 24px;
        border-left: 1px solid #BDBDBD;
        border-right: 1px solid #BDBDBD;
        font-size: 16px;
      }

      @media (max-width: 576px) {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
        margin: 16px 0;
        border: 0;
      }
    }

    &-date {
      padding-right: 5rem;
      padding-left: 3.1rem;
      font-size: 1.8rem;
      line-height: 1.3;
      background-image: url('/pic/profile/calendar.svg');
      background-size: 2.1rem auto;
      background-repeat: no-repeat;
      background-position: 0 50%;

      @media (max-width: 768px) {
        padding-left: 30px;
        font-size: 16px;
        background-size: 21px auto;
      }

      @media (max-width: 576px) {
        flex: 1;
        max-width: 100%;
      }
    }

    &-link {
      align-self: center;
      max-width: 3.2rem;
      flex: 0 0 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      background-color: $blue-02;
      background-size: 1.4rem auto;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.7099 5.29C12.617 5.19627 12.5064 5.12188 12.3845 5.07111C12.2627 5.02034 12.132 4.9942 11.9999 4.9942C11.8679 4.9942 11.7372 5.02034 11.6154 5.07111C11.4935 5.12188 11.3829 5.19627 11.2899 5.29L7.99994 8.59V1C7.99994 0.734784 7.89458 0.48043 7.70705 0.292893C7.51951 0.105357 7.26516 0 6.99994 0C6.73472 0 6.48037 0.105357 6.29283 0.292893C6.1053 0.48043 5.99994 0.734784 5.99994 1V8.59L2.70994 5.29C2.52164 5.1017 2.26624 4.99591 1.99994 4.99591C1.73364 4.99591 1.47825 5.1017 1.28994 5.29C1.10164 5.4783 0.99585 5.7337 0.99585 6C0.99585 6.2663 1.10164 6.5217 1.28994 6.71L6.28994 11.71C6.38505 11.801 6.49719 11.8724 6.61994 11.92C6.73964 11.9729 6.86907 12.0002 6.99994 12.0002C7.13081 12.0002 7.26024 11.9729 7.37994 11.92C7.50269 11.8724 7.61484 11.801 7.70994 11.71L12.7099 6.71C12.8037 6.61704 12.8781 6.50644 12.9288 6.38458C12.9796 6.26272 13.0057 6.13201 13.0057 6C13.0057 5.86799 12.9796 5.73728 12.9288 5.61542C12.8781 5.49356 12.8037 5.38296 12.7099 5.29Z' fill='white'/%3E%3C/svg%3E");

      @media (max-width: 768px) {
        width: 32px;
        max-width: 32px;
        flex: 0 0 32px;
        height: 32px;
        background-size: 14px auto;
      }
    }
  }
  &-enroll {
    width: 34.7rem;
    @media (max-width: 768px) {
      width: 215px;
    }
    @media (max-width: 576px) {
      width: auto;
    }
    &-icon {
      display: flex;
      flex: 1;
    }
  }
}
.orders-item {
  background: #fff;
  &:nth-of-type(odd) {
    background-color: #F2F2F2;
  }
  .section__lk-content-item {
    background: none;
  }
}