
.orders-item {
  position: relative;
  .checkbox-wrapper {
    margin-top: 0!important;
    position: relative;
    bottom: -5px;
    @media (max-width: 540px) {
      top: -15px;
    }
  }
}
