.animated-container {
  transition: .5s ease-in-out;
  opacity: 0;
  transform: translateY(20rem);
}

.active {
  .animated-container {
    opacity: 1;
    transform: translateY(0);
    animation-name: animation-container;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-duration: .5s;
  }
}