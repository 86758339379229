
.label-row {
  display: none;
}
.el-table__header-wrapper table {
  max-width: calc(100% - 48px)!important;
  width: calc(100% - 48px)!important;
}
@media (max-width: 768px) {
  .hidden-sm-only, thead tr th:first-child {
    display: none !important
  }
  .label-row {
    .hidden-sm-only {
      display: table-cell !important;
    }
    display: table-row!important;
    td:not(:first-child) {
      display: none;
    }
  }
}
